'use client';

import React, { useEffect } from 'react';
import markerSDK from '@marker.io/browser';

export default function MarkerIO() {
    useEffect(() => {
        markerSDK.loadWidget({
            project: '668d8afa0a8d94667f67c107',
        });
    }, []);

    return null;
}